/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IVerbasFolha {
  idverbo: string;
  idfolha: string;
  idverbas: string;
  descricao: string;
  qtd: number;
  vencimentos: number;
  descontos: number;
  id: number;
}

export interface ITipoPgtoCheque {
  idfolha: string;
  nome: string;
  urlimg: string;
  cpf: string;
  marcado: boolean;
  total: number;
}

export interface ITipoPgto {
  idfolha: string;
  nome: string;
  tipoconta: string;
  agencia: string;
  conta: string;
  total: number;
}

export interface IGetFolha {
	nome: string;
	cpf: string;
	funcao: string;
	urlimg: string;
  idfolha: string;
	idfun: string;
  produtor: string;
  ctps: string;
  numero: string;
  admissao: Date;
	periodo: string;
	datageracao: Date;
	salario: number;
	carteira: number;
	vencimentos: number;
	descontos: number;
	total: number;
	status: boolean;
	dv: string;
	tipoconta: string;
	agencia: string;
	conta: string;
	tipopagamento: string;
  itens: IVerbasFolha[];
}

export interface IUpdateFolha {
  total: number;
}

export interface IFolhaGera {
  Periodo: string;
}

export interface IImprimirFolha {
  nome: string;
  funcao: string;
  salario: number;
  carteira: number;
  percentualinslubridade: number;
  inss: number;
  insalubridade: boolean,
  horaextra: number;
  bomba: number;
  domingo: number;
  pistola: number;
  salariofamilia: number;
  gratificacao: number;
  vinsalubridade: number;
  falta: number;
  vale: number;
  alimentacao: number;
  verbasfixasv: number;
  verbasfixasd: number;
  verbasexpv: number;
  verbasexpd: number;
  eventosfixov: number;
  eventosfixosd: number;
  eventosexpv: number;
  eventosexpd: number;
  total: number;
}

export interface  IListagemFolha {
  idfolha: string;
  nome: string;
  cpf: string;
  funcao: string;
  urlimg: string;
  salario: number;
  carteira: number;
  vencimentos: number;
  descontos: number;
  total: number;
}

export interface IFomeTipoPgto {
  periodo: string;
  banco: string;
  tipopagamento: string;
  tipoconta: string;
}

interface IVerbasids{
  idverbo: string;
}

type TotalCount = {
  data: IListagemFolha[];
  totalCount: number;
}

type CountCheque = {
  data: ITipoPgtoCheque[];
  totalCount: number;
}

interface EntryFolha {
  folha: IGetFolha;
  verbas: IVerbasFolha[];  
}


const ImprimirFolha = async (periodo: string): Promise<IImprimirFolha[] | Error> => {
  try {
    const { data} = await Api.get( `/imprimirfolha?periodo=${periodo}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const GetAll = async (page = 1, tipo: string, periodo: string, filter = ''): Promise<TotalCount | Error> => {
  try {
    
    const urlRelativa = `/folha?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}&tipo=${tipo}&periodo=${periodo}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const TipoPagamento = async (dados: IFomeTipoPgto): Promise<ITipoPgto[] | Error> => {
  try {
    
    const urlRelativa = `/tipopagamento?periodo=${dados.periodo}&tipopagamento=${dados.tipopagamento}&banco=${dados.banco}&tipoconta=${dados.tipoconta}`;

    const { data } = await Api.get(urlRelativa);

    if (data) { 
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const TipoPagamentoCheque = async (page = 1, fazenda: string, periodo: string): Promise<CountCheque| Error> => {
  try {
    
    const urlRelativa = `/tipopagamento?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&fazenda=${fazenda}&periodo=${periodo}&tipopagamento=cheque`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getById = async (id: string): Promise<IGetFolha | Error> => {
  try {

    const { data } = await Api.get(`/folha/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByIdPrint = async (id: string): Promise<IGetFolha | Error> => {
  try {

    const { data } = await Api.get(`/folha/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const ImprimirRecibo = async (periodo: string, fazenda: string, tipopagamento: string): Promise<EntryFolha[] | Error> => {
  try {
    const urlRelativa = `/imprimirrecibos?fazenda=${fazenda}&periodo=${periodo}&tipopagamento=${tipopagamento}`;
    
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const create = async (periodo: string): Promise<string | Error> => {
  try {

    const { data } = await Api.post(`/folha?periodo=${periodo}`);
    if (Object.keys(data).length === 0) {
      return 'registro ciado com sucesso!';
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const deleteFolha = async (periodo: string): Promise<void | Error> => {
  try {
    await Api.delete(`folha?periodo=${periodo}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const updateById = async (id: string, dados: IUpdateFolha, dadosverbas: IVerbasFolha[], iddeletados: IVerbasids[]): Promise<void | Error> => {
  try {
    const dadosFolha = {idfolha: id, ...dados};
    await Api.put(`/folha/${id}`, [dadosFolha, dadosverbas, iddeletados]);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const FolhaService = {
  create,
  deleteFolha,
  ImprimirFolha,
  GetAll,
  getById,
  getByIdPrint,
  TipoPagamento,
  TipoPagamentoCheque,
  ImprimirRecibo,
  updateById,
};