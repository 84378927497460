/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AutoCompleteVerbas, IVFormErrors, useVForm, VFieldMoney, VForm } from '../../../shared/forms';
import * as yup from 'yup';

interface IFormData {
  idverbo: string;
  idfolha: string;
  idverbas: string;
  descricao: string;
  qtd: number;
  vencimentos: number;
  descontos: number;
  id: number;
}

export interface ConfirmationDialogRawProps {
  id: string;
  idfolha: string;
  value: string;
  open: boolean;
  onDados: (dados: IFormData) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfolha: yup.string().required(),
  idverbo: yup.string().required(),
  idverbas: yup.string().required(),
  descricao: yup.string().required(),
  qtd: yup.number().required(),
  vencimentos: yup.number().required(),
  descontos: yup.number().required(),
  id: yup.number().optional().default(0),
});

export const DialogFolha: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  const {formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [idverbas, setIdVerbas] = useState<string>();

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  const idfun = useMemo(() => {
    return searchParams.get('idfun') || props.id;
  }, [searchParams]);
 
  useEffect(() => {
    // if(idfun != 'novo'){
    //   setIsLoading(true);
    //   FeriasService.getAll(pagina, props.id)
    //     .then((result) => {
    //       setIsLoading(false);
  
    //       if (result instanceof Error) {
    //         alert(result.message);
    //       } else {
    //         // setTotalCount(result.totalCount);
    //         // setRows(result.data);
    //       }
    //     });
    // }
  }, [pagina, idfun, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleuuidVerbas = (uuid: string | undefined) => {
    setIdVerbas(uuid!);
  };
  

  const handleSave = (dados: IFormData) => {
    setIsLoading(true);  
    dados.idfolha=props.idfolha,
    dados.idverbo=props.id;
    dados.descricao='testando',
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if(props.id === 'novo'){
          // props.onDados({iditens: guuid, qtdentrada: 0, descricao: '', localizacao: localizacaoText, filtro:'', referencia: referencia, ...dadosValidados});
          // onClose(value);
          // setIsLoading(false);
        }else{
          props.onDados(dadosValidados);
          onClose(value);
          setIsLoading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};    
        console.log(validationErrors); 
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Folha de Pagamento</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1}>
            <Grid container item direction="row" padding={1} spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={9} lg={12} xl={12}>
                    <AutoCompleteVerbas
                      isExternalLoading={isLoading}
                      idverbas={handleuuidVerbas}
                    /> 
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='qtd'
                      label='Quantidade'
                      // calcular={handleQtd}
                      // disabled={props.status ? isLoading : true}
                    />
                  </Grid>   
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='vencimentos'
                      label='Vencimentos'
                      disabled={isLoading}
                    />
                  </Grid>    
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='descontos'
                      label='Descontos'
                      disabled={isLoading}
                    />
                  </Grid>             
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button size='small' variant='outlined' onClick={handleCancel}>Voltar</Button>      
        <Button size='small' variant='outlined' autoFocus onClick={save}>Salvar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 