/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { VTextField, VForm, useVForm,  AutoCompleteFuncionario,  VFieldMoney, AutoCompletePeriodo } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import { FolhaService, IVerbasFolha } from '../../../shared/services/api/rh/folha/FolhaService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import recibo, { IGetFolha } from './Impressaorecibo';
import { Environment } from '../../../shared/environment';
import { DialogFolha } from './DialogFolha';

interface IFormData {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string; 
  idoperacao: string;
  totalvale: number;
}

interface IVerbas {
  idverbo: string;
  idfolha: string;
  idverbas: string;
  descricao: string;
  qtd: number;
  vencimentos: number;
  descontos: number;
  id: number;
}

interface IItensids{
  idverbo: string;            
}

export const DetalheDaFolha: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose } = useVForm();
  const [rowsRecibo, setRowsRecibo] = useState<IGetFolha>();
  const [rows, setRows] = useState<IVerbasFolha[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [, setIdfun] = useState('');
  const [cpf, seCpf] = useState('');
  const [funcao, setFuncao] = useState('');
  const [urlimg, setUrlimg] = useState('');
  const [statuss, ] = useState(false);
  const [openfolha, setOpenfolha] = useState(false);
  const [idfolha, setIdFolha] = useState('');
  const [idverbo, setIdVerbo] = useState('');
  const [edit, setEdit] = useState(false);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
 
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      FolhaService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/folha');
          } else {
            setRowsRecibo(result);
            setFuncao(result.funcao);
            seCpf(result.cpf);
            setIdfun(result.idfun);
            if (result.urlimg != null) {
              setUrlimg(Environment.URL_IMGFUNCIONARIO+result.urlimg);
            } 
            formRef.current?.setData(result);
            setNome(result.nome);
            setRows(result.itens);
          }
        });
    } else {
      formRef.current?.setData({
      });
    }  
  }, [id]);

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(process.env.URL_IMGFUNCIONARIO+urlimg);
    }
  };   
   
  const handleSave = (dados: IFormData) => {
    //
  };          
     
  const handlePrint = () => {   
    if(rowsRecibo){    
      recibo(rowsRecibo);      
    }
  };

  const handleCloseFolha = () => {
    setOpenfolha(false);
  };  

  type RowsType = IVerbas[];
  
  const handleVerbas = (dados: IVerbas) => {
    const index = rows.findIndex((listItem) => listItem.idverbo === dados.idverbo);
    let updatedList: RowsType;
    if (index !== -1) {
      updatedList = [...rows];   
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);
    if(updatedList.length != 0){
      setEdit(true);
    }else{
      setEdit(false);
    }
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = {idverbo: id};  
     
      rowsids.push(meuObjeto); 
      
      const indexToDelete = rows.findIndex((item) => item.idverbo === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
        if(updatedList.length != 0){
          setEdit(true);
        }else{   
          setEdit(false);   
        }
      }
    }
  };

  useEffect(() => {
    const totalvencimento = rows.reduce((accumulator, currentItem) => accumulator + currentItem.vencimentos, 0);
    const totaldesconto = rows.reduce((accumulator, currentItem) => accumulator + currentItem.descontos, 0);
    formRef.current?.setFieldValue('vencimentos', totalvencimento);
    formRef.current?.setFieldValue('descontos', totaldesconto);
    const vtotal = totalvencimento - totaldesconto;
    formRef.current?.setFieldValue('total', vtotal);
  }, [rows]);


  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={id !== 'novo'}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/folha')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmImpressao={() => handlePrint()}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1}  display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid marginTop={0.5} container item direction="row" spacing={2} rowSpacing={1} >
              <Grid marginLeft={3} item xs={12} sm={12} md={2} lg={2} xl={1} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg || undefined}
                    sx={{ width: '100%', height: 150 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Grid container item direction="row" spacing={1} rowSpacing={1} >
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    {id === 'novo' ? (
                      <AutoCompleteFuncionario 
                        isExternalLoading={id === 'novo' ? isLoading : true}
                        urlfoto={handlefoto}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        size='small'
                        value={nome}
                        label='Nome do Funcionario'
                        disabled={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                      fullWidth
                      size='small'
                      value={cpf}
                      label='CPF'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size='small'
                      value={funcao}
                      label='Função'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    {id === 'novo' ? (
                      <AutoCompletePeriodo 
                        isExternalLoading={id === 'novo' ? isLoading : true}/>
                    ) : (
                      <VTextField
                        fullWidth
                        size='small'
                        name='periodo'
                        label='Período'
                        disabled={true}
                      />
                    )}                       
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='tipopagamento'
                      label='Tipo de Pagamento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='salario'
                      label='Salario'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='carteira'
                      label='S. Carteira'
                      disabled={id === 'novo' ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='total'
                      label='Total'
                      disabled={id === 'novo' ? isLoading : true}
                    />
                  </Grid>                
                  
                </Grid>              
              </Grid> 
            </Grid> 
            <Grid item xs={12} sm={12} md={12} lg={12} xl={11} marginLeft={3}>
              <TableContainer component={Paper} variant="outlined" >
                <Table size="small">
                  <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                    <TableRow >
                      <TableCell style={{ color: '#fff' }} align='left'>Quantidade</TableCell>
                      <TableCell style={{ color: '#fff' }} align='left'>Descrição</TableCell>
                      <TableCell style={{ color: '#fff' }} align='right'>Vencimentos</TableCell>
                      <TableCell style={{ color: '#fff' }} align='right'>Descontos</TableCell>
                      <TableCell style={{ color: '#fff' }} align='center'>
                        <IconButton color='inherit' disabled={statuss === false ? isLoading : true}  aria-label="add to shopping cart" size="large">
                          <AddCircleOutlineIcon  onClick={() => {setIdVerbo('novo'); setOpenfolha(true);}} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody> 
                    {rows.map(row => (
                      <TableRow key={row.idverbo} 
                        hover
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell align='left' >{row.qtd.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits:2})}</TableCell>
                        <TableCell align='left' >{row.descricao}</TableCell>
                        <TableCell align='right'>{row.vencimentos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits:2})}</TableCell>
                        <TableCell align='right'>{row.descontos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits:2})}</TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => {setIdFolha(row.idfolha); setIdVerbo(row.idverbo); setOpenfolha(true);}}>
                            <Icon >edit</Icon>
                          </IconButton>
                          <IconButton disabled={isLoading}  size="small" onClick={() => {handleDelete(row.idverbo);}}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LinearProgress variant='indeterminate' />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </VForm>
      <DialogFolha
        id={idverbo}
        idfolha={idfolha}
        value={''}  
        open={openfolha} 
        onDados={handleVerbas} 
        onClose={handleCloseFolha}
      /> 
    </LayoutBaseDePaginaNfe>
  );
};