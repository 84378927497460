/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError, isAxiosError } from 'axios';
import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { v4 as uuidv4 } from 'uuid';

export interface IListagemContasPagar {
  idcontaspagar: string;
  dataoperacao: Date;
  dataemissao: Date;
  vencimento: Date;
  nome: string;
  cpf: string;
  parcela: string;
  nossonumero: string;
  numerodoc: string;
  valornota: number;
  valorpago: number;
  desconto: number;
  acrescimo: number;
  valorparcela: number;
  status: boolean;
  marcado: boolean;
  lcdpr: boolean;
  valorpagar: number;
  nfepagar: number;
  venc: boolean;
  boleto: boolean;
}

export interface IMarcados{
  idcontaspagar: string;
	marcado: boolean;
	desconto: number;
	valorpagar: number;
}

export interface ICheque{
  idcheque: string;
  banco: string;
  agencia: string;
  conta: string; 
  datadeposito: Date;
  titular: string;
  valor: number;
  numero: string;
}

type TotalCount = {
    data: IListagemContasPagar[];
    totalCount: number;
    selecionado: number;
    total: number;
}

interface IPagas {
  valorpago: number;
  valorselecionado: number;
  idconta: number;
  databaixa: Date;
  numeroch: string;
  tipocaixa: boolean;
  valornfe: number;
}

interface IPagar {
	idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number,
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
}

interface IGetPagar {
	idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
  patrimonio: string;
  npatrimonio: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number,
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
  pagas: number,
  status: boolean,
}

interface IUpdatePagar {
	idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
  idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number,
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
  pagas: number,
  status: boolean,
}


interface IGetReceber {
  idparceiro: string;
  idcultivo?: string | null;
  idplanoconta: string;
  idfazenda: string;
  idprodutor: number;
  emissao: Date;
  vencimento: Date;
  historico: string;
  historicolcdpr: string;
  lcdpr: boolean;
  numerodoc: string;
  numeronfe: string;
  valorcompra: number;
  valorparcela: number;
  acrescimos: number;
  desconto: number;
  recebido: number;
  receber: number;
  valornfe: number;
  nfeparcela: number;
  nfereceber: number;
  nferecebido: number;
  tipo: number;
  tipodoc: number;
  status: boolean;
  recebidas: number;
}

interface IParcelas {
  idcontaspagar: string;
  idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataoperacao: Date;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number, 
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

const create = async (dados: IPagar): Promise<string | Error> => {
  try {   
    const guuid = uuidv4();

    const { data } = await ApiFinanceira.post<IPagar>('/contaspagar',  [{idcontaspagar: guuid, ...dados}]);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    const err = error as AxiosError; 
    if (err.response) {
      return new Error('Error ' + err.response.data);
    } else if (err.request) {
      if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
        const e = error as ErrorResponse;
        const errorMessages = Object.entries(e.data.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('; ');
        return new Error(errorMessages);
      } else {
        return new Error('Erro ao Atualizar Receber');
      }
    } else {
      return new Error('Error' +  err.message);
    }
  }
};

const createparcelas = async (dados: IParcelas[]): Promise<string | Error> => {
  try {

    const guuid = dados[0].idcontaspagar;

    const { data } = await ApiFinanceira.post<IParcelas>('/contaspagar',  dados);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IUpdatePagar): Promise<void | Error> => {
  try {

    await ApiFinanceira.put(`/contaspagar/${id}`, dados);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Atualizar Contas Pagar');
    }}
};

const getAll = async (page = 1, dataInicial = '', dataFinal='', filter = '', tipo = '' ): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/contaspagar?page=${page}&limit=${20}&filter=${filter}&tipo=${tipo}&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 20), 
        selecionado: Number(headers['x-total-selecionado'] || 0),
        total: Number(headers['x-total-valor'] || 0),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages); 
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const Marcados = async (dados: IMarcados[]): Promise<void | Error> => {
  try {

    await ApiFinanceira.post('/contaspagarmarcado', dados);    
   
    return;

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Marcar');
    }}
};

const Baixa = async (dados: IPagas, pagar: IListagemContasPagar[]): Promise<void | Error> => {
  try {  
    let historicos = ''; 
    if(dados.valorpago === dados.valorselecionado){
      const baixa = pagar.map(conta => {
        historicos += `${conta.numerodoc}, `;
        return {
          idcontaspagar: conta.idcontaspagar,
          idconta: dados.idconta,
          datalan: dados.databaixa,
          valor: conta.valorpagar,
          numeroch: dados.numeroch,
          historico: `Pagamentos dos numeros ${historicos.slice(0, -2)}`,
          total: dados.valorselecionado,
          tipocaixa: dados.tipocaixa,
          valornfe: conta.valorpagar,
        };
      });
      await ApiFinanceira.post('/contaspagarbaixa', baixa); 
      return;
    }else{
      const baixa = pagar.map(conta => {
        return {
          idcontaspagar: conta.idcontaspagar,
          idconta: dados.idconta,
          datalan: dados.databaixa,
          valor: dados.valorpago,
          numeroch: dados.numeroch,
          valornfe: 0,
        };
      });
      await ApiFinanceira.post('/contaspagarbaixaparcial', baixa); 
      return;   
    }
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};


const getById = async (id: string): Promise<IGetPagar | Error> => {
  try {
    const { data } = await ApiFinanceira.get(`/contaspagar/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Get');
    }}
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFinanceira.delete(`/contaspagar/${id}`);

  }catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Apagar o Registro');
    }}
};


export const ContasPagarService = {  
  getAll,
  Marcados,
  Baixa,
  create,
  updateById,
  getById,
  deleteById,
  createparcelas, 
};