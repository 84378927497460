import { ApiFinanceira } from '../../axiosfinanceiro';

interface Item {
    iditensrom: string;
    qtd: number;
    descricao: string;
    fazenda: string;
    conversor: number;
    un: string;
    subtotal: number;
    total: number;
    qtdajuste: number;
    totaldesconto: number;
    preco: number;    
  }   
  
  interface romaneio {
    idromaneio: string,
    dataemissao: Date
    vencimento: Date;
    datafechado: Date;
    numero: number;
    numeronfe: string;
    valortotal: number
    nome: string;
    cpf: string;
    valoritens: number;
    seguro: number;
    frete: number;
    icmsmercaodira: number; 
  }
  
  interface Entry {
    romaneios: romaneio;
    itens: Item[];
  }
  

  interface itensproducao {
    descricao: string; 
    quantidade: number;
		media: number;
		total: number;
		qtdajuste: number;
		mediadesconto: number;
		desconto: number;
  }

  interface ItemDetalhes {
    iditensrom: string;
		qtd: number;
    qtdkg: number;
    qtdajuste: number;
		un: string;
		preco: number;
		totaldesconto: number;
		conversor: number;
		subtotal: number;
    total: number;
		numero: number;
		dataemissao: Date;
		descricao: string;
		fazenda: string;
  }   
  
  interface romaneioDetalhes {
    idcultivo: string;
    descricao: string;
    qtd: number;
    qtdkg: number;
    qtdajuste: number;
    subtotal: number;
    desconto: number;
    total: number;
  }

  interface EntryProducao {
    romaneios: romaneioDetalhes;
    itens: ItemDetalhes[];
  }
  
const RRomaneios = async (status: string, parceiro: string, datainicial: string, datafinal: string, detalhes: boolean): Promise<Entry[] | Error> => {
  try {
    const urlRelativa = `/rromaneio?status=${status}&parceiro=${parceiro}&datainicial=${datainicial}&datafinal=${datafinal}&detalhes=${detalhes}`;
    
    const { data } = await ApiFinanceira.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RProducaoResumido = async (fazenda: string, cultivo: string, datainicial: string, datafinal: string, detalhes: boolean): Promise<itensproducao[] | Error> => {
  try {
    const urlRelativa = `/rproducao?fazenda=${fazenda}&cultivo=${cultivo}&datainicial=${datainicial}&datafinal=${datafinal}&detalhes=${detalhes}`;
    
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RProducaoDetalhada = async (fazenda: string, cultivo: string, datainicial: string, datafinal: string, detalhes: boolean): Promise<EntryProducao[] | Error> => {
  try {
    const urlRelativa = `/rproducao?fazenda=${fazenda}&cultivo=${cultivo}&datainicial=${datainicial}&datafinal=${datafinal}&detalhes=${detalhes}`;
    
    const { data } = await ApiFinanceira.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


export const RelatorioRomaneiosService = {
  RRomaneios,
  RProducaoResumido,
  RProducaoDetalhada,
};