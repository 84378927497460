/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
interface ItemDetalhes {
  iditensrom: string;
  qtd: number;
  qtdkg: number;
  qtdajuste: number;
  un: string;
  preco: number;
  totaldesconto: number;
  conversor: number;
  subtotal: number;
  total: number;
  numero: number;
  dataemissao: Date;
  descricao: string;
  fazenda: string;
}   

interface romaneioDetalhes {
  idcultivo: string;
  descricao: string;
  qtd: number;
  qtdkg: number;
  qtdajuste: number;
  subtotal: number;
  desconto: number;
  total: number;
}

interface EntryProducao {
  romaneios: romaneioDetalhes;
  itens: ItemDetalhes[];  
}



async function RRelatorioProducaoDetalhes(data: EntryProducao[], datai: string, dataf: string){
  const TotalGeral = data.reduce((acc, item) => acc + item.romaneios.total, 0);
  const TotalQtd = data.reduce((acc, item) => acc + item.romaneios.qtd, 0);
  const TotalQtdkg = data.reduce((acc, item) => acc + item.romaneios.qtdkg, 0);
  const TotalQtdAjuste = data.reduce((acc, item) => acc + item.romaneios.qtdajuste, 0);
  const TotalSubtotal = data.reduce((acc, item) => acc + item.romaneios.subtotal, 0);
  const TotalDesconto = data.reduce((acc, item) => acc + item.romaneios.desconto, 0);

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    pageOrientation: 'landscape',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 800],  
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },  
              {
                width: 800,
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Produção Detalhados', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Período de: '+datai+' a '+dataf,  alignment: 'left', fillColor: '#dddddd', fontSize: 8},     
      data.map(e => {
        const { romaneios, itens } = e;
        const TTotal = itens.reduce((acc, item) => acc + item.total, 0);
        const TQtd = itens.reduce((acc, item) => acc + item.qtd, 0);
        const TQtdkg = itens.reduce((acc, item) => acc + item.qtdkg, 0);
        const TQtdAjuste = itens.reduce((acc, item) => acc + item.qtdajuste, 0);
        const TSubTotal = itens.reduce((acc, item) => acc + item.subtotal, 0);
        const TDesconto = itens.reduce((acc, item) => acc + item.totaldesconto, 0);
        const rows = itens.map((row) => {
          return [
            {text: moment(row.dataemissao).format('DD/MM/YYYY'), bold: true, fontSize: 7, alignment: 'center'},
            {text: row.fazenda, alignment: 'left',  fontSize: 7},
            {text: row.numero, alignment: 'right',  fontSize: 7},
            {text: row.descricao, alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtdkg) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtd) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtdajuste) , fontSize: 7, alignment: 'right'},
            {text: row.un, alignment: 'center',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br',).format(row.conversor) , fontSize: 7, alignment: 'center'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.preco) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.subtotal) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.totaldesconto) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total) , fontSize: 7, alignment: 'right'},
          ];
        });  
        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  body: [
                    [
                      {text: 'Cultivo: '+ e.romaneios.descricao, bold: true, fontSize: 9, alignment: 'left', border: [false, false, false, false]},
                    ],
                  ] 
                },  
              },
              {   
                table: {
                  widths: [42, 150, 40, 120, 50, 50, 50, 15, 15, 30, 50, 35, 50],  
                  body: [
                    [
                      { text: 'Data',  alignment: 'left', fillColor: '#dddddd', fontSize: 9}, 
                      { text: 'Fazenda',  alignment: 'left', fillColor: '#dddddd', fontSize: 9}, 
                      { text: 'Romaneio',  alignment: 'center', fillColor: '#dddddd', fontSize: 9},
                      { text: 'Fruta / Produto',  alignment: 'center', fillColor: '#dddddd', fontSize: 9},   
                      { text: 'Quantidade KG',  alignment: 'center', fillColor: '#dddddd', fontSize: 9}, 
                      { text: 'Quantidade',  alignment: 'center', fillColor: '#dddddd', fontSize: 9}, 
                      { text: 'Qtd. Ajuste',  alignment: 'center', fillColor: '#dddddd', fontSize: 9},
                      { text: 'UN',  alignment: 'center', fillColor: '#dddddd', fontSize: 9},
                      { text: 'KG',  alignment: 'center', fillColor: '#dddddd', fontSize: 9},
                      { text: 'Preço',  alignment: 'right', fillColor: '#dddddd', fontSize: 9},
                      { text: 'Sub-Total',  alignment: 'right', fillColor: '#dddddd', fontSize: 9},
                      { text: 'Desconto',  alignment: 'right', fillColor: '#dddddd', fontSize: 9},
                      { text: 'Total',  alignment: 'right', fillColor: '#dddddd', fontSize: 9},
                    ],
                    ...rows,
                  ]
                },       
              },   
              {     
                table: {
                  widths: [42, 150, 40, 120, 50, 50, 50, 15, 15, 30, 50, 35, 50],  
                  body: [
                    [
                      { text: '',  border: [false, false, false, false]}, 
                      { text: '',  border: [false, false, false, false]}, 
                      { text: '',  border: [false, false, false, false]}, 
                      { text: '',  border: [false, false, false, false]},   
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TQtdkg), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TQtd), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TQtdAjuste), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                      { text: '',  border: [false, false, false, false]}, 
                      { text: '',  border: [false, false, false, false]}, 
                      { text: '',  border: [false, false, false, false]}, 
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TSubTotal), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TDesconto), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TTotal), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
                    ],
                    [
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},  
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},  
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                    ]
                  ]
                },       
              }                                          
            ]
          ]                           
            
        ];
      }     
      ),
      //  { margin: [0, 2, 18, 0],  text: 'Total Geral: '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TotalGeral) , fontSize: 8, alignment: 'right'},
      {
        table: {
          widths: [42, 150, 40, 120, 50, 50, 50, 15, 15, 30, 50, 35, 50],  
          body: [
            [
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              { text: 'Totais', alignment: 'right', fontSize: 8, bold: true,  border: [false, false, false, false]},   
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalQtdkg), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalQtd), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalQtdAjuste), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalSubtotal), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalDesconto), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral), fontSize: 9, alignment: 'right', border: [false, false, false, false]},
            ],
          ]
        },       
      }
    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}

export default RRelatorioProducaoDetalhes;